import Listagem from '../componentes/Usuario/listagem';
import Cadastro from '../componentes/Usuario/Cadastro';

function Usuario(props) {  
  
  return (

    <section>

      { props.acao === "Listar" &&
        <Listagem />
      }
      { props.acao !== "Listar" &&
        <Cadastro acao={props.acao} />
      }    

    </section>

  );

}

export default Usuario;
