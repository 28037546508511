import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { filiadoAPI } from "../../servicos/filiado";


function Senha() {

    const breadcrumbs = ['Admin', 'Filiado', 'Edição', 'Senha'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [fi100_dsc_senha, set100_dsc_senha] = useState('');
    const [senhaConfirm, setSenhaConfirm] = useState('');

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/admin/filiado';
        navigate(path);
    }

    const parametros = useParams();

    useEffect(() => {
        if(parametros.hash) {
            buscarFiliado(parametros.hash);    
        }
    }, [parametros])

    async function buscarFiliado(hash){

        filiadoAPI.get(`/${hash}`)
            .then(resposta => {
            
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )

    }    

    const validaForm = (event) => {
        event.preventDefault();

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if ((fi100_dsc_senha == '') || (senhaConfirm == '')) {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a senha.");
            return false;
        }

        if ((fi100_dsc_senha !== senhaConfirm)) {
            setExibeErro(true);
            setMensagemErro("As senhas devem estar iguais.");
            return false;
        }

        if(parametros.hash){
            SalvarSenha(parametros.hash);
        }else{
            setExibeErro(true);
            setMensagemErro("OPS Desconheço o cadastro deste filiado.");
            return false;
        }
        
    }

    async function SalvarSenha(hash) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        filiadoAPI.patch(`/senha/${hash}`,
            {
                'fi100_dsc_senha': fi100_dsc_senha
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Senha alterada com sucesso.');
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Reiniciar Senha do Filiado' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="fi100_dsc_senha" placeholder="" name="fi100_dsc_senha" value={fi100_dsc_senha} onChange={evento => set100_dsc_senha(evento.target.value)} />
                                <label for="floatingInput">Senha</label>
                            </div>
                            <div class="col-md-6 form-floating">                                
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="fi100_dsc_senha_2" placeholder="" value={senhaConfirm} onChange={evento => setSenhaConfirm(evento.target.value)} />
                                <label for="floatingInput">Confirmação da Senha</label>
                            </div>
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Senha;

