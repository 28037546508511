import MenuLateral from "../MenuLateral/menulateral";
import MenuLateralMobile from "../MenuLateral/menulateralmobile";
import UsuarioTabela from "./tabela";

function Listagem(){    

    return (
        <div class="container-fluid">
            <div class="row">
                <MenuLateral menu="usuario" admin={true}/>
                <MenuLateralMobile />
                <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
                <div id="conteudo" class="col-12 col-lg-10">
                    <UsuarioTabela />
                </div>           
            </div>
        </div>
   
    )
}

export default Listagem;

