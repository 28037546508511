function ListagemOpcoes(props){

    const esconderTamanhoPagina = props.esconderTamanhoPagina;
    const esconderOpcaoFiltro = props.esconderOpcaoFiltro;
    const esconderCampoFiltro = props.esconderCampoFiltro;

    return(
        <div class="row row-padding-conteudo">
            <div class="col-md-3">
                {!esconderCampoFiltro &&
                    <div class="input-group mb-3 input-search">
                        <span class="input-group-text buscar" id="basic-addon1">
                            <span class="material-symbols-outlined">search</span>
                        </span>
                        <input type="text" class="form-control" placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="basic-addon1"
                        />
                    </div>
                }                    
            </div>
            <div class="col-md-2">
                {!esconderOpcaoFiltro && 
                    <button type="button" class="btn-filter mb-3 btn btn-primary">
                        <span class="material-symbols-outlined">filter_list</span>Filtro
                    </button>
                }
            </div>
            {!esconderTamanhoPagina &&
                <div class="col-md-7 mb-3 ordenacao">
                
                    <label for="staticEmail" class="col-form-label">Itens por página</label>
                    <select id="inputState" class="form-select">
                        <option selected>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                    </select>
                    <a class="mobile-hide" href="cards-filiados.html">
                        <span class="material-symbols-outlined">cards</span>
                    </a>                
                </div>
            }
        </div>

    )

}

export default ListagemOpcoes;