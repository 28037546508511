function ListagemFeedback(props) {

    const mostraErro = props.displayErro;
    const mensagemErro = props.mensagemErro;

    const mostraSucesso = props.displaySucesso;
    const mensagemSucesso = props.mensagemSucesso;

    return (
        <div class="row row-padding-conteudo">
            {mostraSucesso &&
            <div class="col-12">
                <div class="alert alert-success alert-w100" role="alert">
                    <span class="material-symbols-outlined">error</span>{mensagemSucesso}
                </div>
            </div>
            }
            {mostraErro &&
                <div class="col-12">
                    <div class="alert alert-danger alert-w100" role="alert">
                        <span class="material-symbols-outlined">error</span>{mensagemErro}
                    </div>
                </div>
            }
        </div>
    )

}

export default ListagemFeedback;