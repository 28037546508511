//import './login.css';
import MenuLateral from "../MenuLateral/menulateral";
import MenuLateralMobile from "../MenuLateral/menulateralmobile";
import FiliadoTabela from "./tabela";


function Listagem(){    
    
    return (
        <div class="container-fluid">
            <div class="row">
                <MenuLateral menu="filiado" admin={true}/>
                <MenuLateralMobile />
                <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
                <div id="conteudo" class="col-12 col-lg-10">
                    <FiliadoTabela />
                </div>           
            </div>
        </div>
   
    )
}

export default Listagem;

