import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { filiadoAPI } from "../../servicos/filiado";


function Incluir() {

    const breadcrumbs = ['Admin', 'Filiado', 'Novo'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [fi100_dsc_filiado, set100_dsc_filiado] = useState('');
    const [fi100_num_cnpj, set100_num_cnpj] = useState('');
    const [fi100_dsc_email, set100_dsc_email] = useState('');
    const [fi100_dsc_senha, set100_dsc_senha] = useState('');
    const [fi100_num_telefone, set100_num_telefone] = useState('');
    const [fi100_key_detran, set100_key_detran] = useState('');
    const [fi100_dsc_senha_detran, set100_dsc_senha_detran] = useState('');

    const [senhaConfirm, setSenhaConfirm] = useState('');

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/admin/filiado';
        navigate(path);
    }

    const validaForm = (event) => {
        event.preventDefault();

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (fi100_dsc_filiado == '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o nome.");
            return false;
        }

        if (fi100_dsc_email == '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o e-mail.");
            return false;
        }

        if ((fi100_dsc_senha == '') || (senhaConfirm == '')) {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar a senha.");
            return false;
        }

        if ((fi100_dsc_senha !== senhaConfirm)) {
            setExibeErro(true);
            setMensagemErro("As senhas devem estar iguais.");
            return false;
        }

        SalvarAfiliado();
    }

    async function SalvarAfiliado() {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        filiadoAPI.post('/',
            {
                'fi100_dsc_filiado': fi100_dsc_filiado,
                'fi100_num_cnpj': fi100_num_cnpj,
                'fi100_dsc_email': fi100_dsc_email,
                'fi100_dsc_senha': fi100_dsc_senha,
                'fi100_num_telefone': fi100_num_telefone,
                'fi100_key_detran': fi100_key_detran,
                'fi100_dsc_senha_detran': fi100_dsc_senha_detran
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Filiado cadastrado com sucesso.');
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Novo Filiado' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_dsc_filiado" placeholder="" name="fi100_dsc_filiado" value={fi100_dsc_filiado} onChange={evento => set100_dsc_filiado(evento.target.value)} />
                                <label for="floatingInput">Nome do Filiado</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_num_cnpj" placeholder="00.000.000/0001-00" name="fi100_num_cnpj" value={fi100_num_cnpj} onChange={evento => set100_num_cnpj(evento.target.value)} />
                                <label for="floatingInput">CNPJ</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="email" class="form-control" id="fi100_dsc_email" placeholder="name@example.com" name="fi100_dsc_email" value={fi100_dsc_email} onChange={evento => set100_dsc_email(evento.target.value)} />
                                <label for="floatingInput">E-mail</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="fi100_num_telefone" placeholder="(00) 00000-0000" name="fi100_num_telefone" value={fi100_num_telefone} onChange={evento => set100_num_telefone(evento.target.value)} />
                                <label for="floatingInput">Telefone</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="text" class="form-control" id="fi100_key_detran" placeholder="" name="fi100_key_detran" value={fi100_key_detran} onChange={evento => set100_key_detran(evento.target.value)} />
                                <label for="floatingInput">Detran-PR Cliente ID</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="text" class="form-control" id="fi100_dsc_senha_detran" placeholder="" name="fi100_dsc_senha_detran" value={fi100_dsc_senha_detran} onChange={evento => set100_dsc_senha_detran(evento.target.value)} />
                                <label for="floatingInput">Detran-PR Client Secret</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="fi100_dsc_senha" placeholder="" name="fi100_dsc_senha" value={fi100_dsc_senha} onChange={evento => set100_dsc_senha(evento.target.value)} />
                                <label for="floatingInput">Senha</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="password" class="form-control" id="fi100_dsc_senha_2" placeholder="" value={senhaConfirm} onChange={evento => setSenhaConfirm(evento.target.value)} />
                                <label for="floatingInput">Confirmação da Senha</label>
                            </div>
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Incluir;

