import axios from "axios";
import { useNavigate } from "react-router-dom";

const URL = 'https://conexaodetranpr.sindilocpr.com.br/api/usuario';

const usuariosAPI = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

usuariosAPI.interceptors.request.use(function (config) {
    const Token = sessionStorage.getItem('token');
    if((Token) && (config.headers)){
        config.headers.Authorization = `Bearer ${Token}`
    }
    return config;
}, function (error) {
    console.log("Erro na API do Usuário");
    return Promise.reject(error);
});


usuariosAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response?.status === 401) {
    let navigate = useNavigate();
    navigate('/admin');
    return Promise.reject();
  }
  return Promise.reject(error);
});

async function getUsuarios(){   

    const response = await usuariosAPI.get('/');   
    return response.data;
}

export {
    getUsuarios,
    usuariosAPI,
};