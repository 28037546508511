function Paginacao(props){

    const esconder = props.esconder;

    return (
        <div class="row">
            <div class="col-12">
                {!esconder &&
                    <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item prev-next"><a class="page-link" href="#">Previous</a></li>
                            <li class="page-item"><a class="page-link active" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item prev-next"><a class="page-link" href="#">Next</a></li>
                        </ul>
                    </nav>
                }
            </div>
        </div>
    )
}

export default Paginacao;