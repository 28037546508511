import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function Topo(props){
    
    const titulo = props.titulo;
    const breadcrumbs = props.breadcrumbs;
    const btnincluir = props.btnincluir;
    const destino = props.destino;

    let navigate = useNavigate();

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('Nome');
        sessionStorage.removeItem('Key');
        
        if(props.admin){
            navigate('/admin');
        }else{
            navigate('/');
        }        
    }

    const [nomeUsuario, setNomeUsuario] = useState('');

    useEffect(() => {
        CarregarNome();        
    }, []);

    const Token = sessionStorage.getItem('token');

    function CarregarNome(){
        setNomeUsuario(sessionStorage.getItem('Nome'));
    }

    return (
        <div class="row row-padding-conteudo topo-conteudo">
            <div class="col-12 text-end user tablet-hide mobile-hide">
                <div class="dropdown">
                    <button class="btn-dropdown btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {nomeUsuario}
                    </button>
                    <ul class="dropdown-menu">
                            <li>
                            <a class="dropdown-item align-icon">
                                <span class="material-symbols-outlined">manage_accounts</span>Editar Perfil
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item align-icon action-disable" onClick={logout}>
                                <span class="material-symbols-outlined">logout</span>Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-6 text-start topo-conteudo">
                <h1>{titulo}</h1>  
                <nav  aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        { breadcrumbs.map( (menu) => (
                            <li class="breadcrumb-item"><a>{menu}</a></li>
                            ))
                        }
                    </ol>
                </nav>
            </div>                  

            <div class="col-6 text-end topo-conteudo">
                {btnincluir ? <a href={destino} class="btn btn-primary-blue">Novo</a> : ''}
            </div>                               
        </div>
    )

}

export default Topo;