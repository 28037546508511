import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { usuariosAPI } from "../../servicos/usuarios";


function Editar() {

    const breadcrumbs = ['Admin', 'Usuário', 'Edição'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [us100_dsc_usuario, set100_dsc_usuario] = useState('');
    const [us100_dsc_email, set100_dsc_email] = useState('');

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/admin/usuario';
        navigate(path);
    }

    const parametros = useParams();

    useEffect(() => {
        if(parametros.hash) {
            buscarFiliado(parametros.hash);    
        }
    }, [parametros])

    async function buscarFiliado(hash){

        usuariosAPI.get(`/${hash}`)
            .then(resposta => {

                set100_dsc_usuario(resposta.data.us100_dsc_usuario);
                set100_dsc_email(resposta.data.us100_dsc_email);
            
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )

    }    

    const validaForm = (event) => {
        event.preventDefault();

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (us100_dsc_usuario == '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o nome.");
            return false;
        }

        if (us100_dsc_email == '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o e-mail.");
            return false;
        }

        if(parametros.hash){
            SalvarUsuario(parametros.hash);
        }else{
            setExibeErro(true);
            setMensagemErro("OPS Desconheço o cadastro deste usuário.");
            return false;
        }
        
    }

    async function SalvarUsuario(hash) {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        usuariosAPI.put(`/editar/${hash}`,
            {
                'us100_dsc_usuario': us100_dsc_usuario,
                'us100_dsc_email': us100_dsc_email
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Usuário alterado com sucesso.');
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Editar Usuário' breadcrumbs={breadcrumbs} btnincluir={false} admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                        <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="us100_dsc_usuario" placeholder="" name="us100_dsc_usuario" value={us100_dsc_usuario} onChange={evento => set100_dsc_usuario(evento.target.value)} />
                                <label for="floatingInput">Nome do Usuário</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="email" class="form-control" id="us100_dsc_email" placeholder="name@example.com.br" name="us100_dsc_email" value={us100_dsc_email} onChange={evento => set100_dsc_email(evento.target.value)} />
                                <label for="floatingInput">E-mail</label>
                            </div>                    
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Editar;

