import axios from "axios";

const URL = 'https://conexaodetranpr.sindilocpr.com.br/api/filiado';

const filiadoAPI = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        Content: 'application/json'
    }
})

filiadoAPI.interceptors.request.use(function (config) {
    const Token = sessionStorage.getItem('token');
    if((Token) && (config.headers)){
        config.headers.Authorization = `Bearer ${Token}`
    }
    return config;
}, function (error) {
    console.log("Erro na API de Filiados");
    return Promise.reject(error);
});


filiadoAPI.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  return Promise.reject(error);
});


export {
    filiadoAPI
};