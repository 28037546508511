import MenuLateral from "../MenuLateral/menulateral";
import MenuLateralMobile from "../MenuLateral/menulateralmobile";
import Incluir from "./Incluir";
import Editar from "./Editar";
import Senha from "./Senha";

function Cadastro(props){    
 
    const mostraEditar = props.editar;
    const mostraSenha = props.senha;
    const mostraIncluir = props.incluir;             

    return (
        <div class="container-fluid">
            <div class="row">
                <MenuLateral menu="filiado" admin={true}/>
                <MenuLateralMobile />
                <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
                <div id="conteudo" class="col-12 col-lg-10">
                    {mostraEditar &&
                        <Editar />
                    }
                    {mostraSenha &&
                        <Senha />
                    }
                    {mostraIncluir &&
                        <Incluir />
                    }
                </div>           
            </div>
        </div>  
    )
}

export default Cadastro;

