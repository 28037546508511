import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { veiculosAPI } from "../../servicos/veiculos";


function Incluir() {

    const breadcrumbs = ['Home', 'Veículo', 'Novo'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [ve100_dsc_chassi, set100_dsc_chassi] = useState('');
    const [ve100_num_renavam, set100_num_renavam] = useState('');
    const [ve100_dsc_placa, set100_dsc_placa] = useState('');
    const [ve100_key_nfe, set100_key_nfe] = useState('');
    

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/filiado/veiculo';
        navigate(path);
    }

    const validaForm = (event) => {
        event.preventDefault();

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        if (ve100_dsc_chassi == '') {
            setExibeErro(true);
            setMensagemErro("OPS Você esqueceu de informar o chassi.");
            return false;
        }

        SalvarVeiculo();
    }

    async function SalvarVeiculo() {

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');

        veiculosAPI.post('/',
            {
                've100_dsc_chassi' : ve100_dsc_chassi,
                've100_num_renavam' : ve100_num_renavam,
                've100_dsc_placa' : ve100_dsc_placa,
                've100_key_nfe' : ve100_key_nfe            
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Veículo cadastrado com sucesso.');
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })


    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Novo Veículo' breadcrumbs={breadcrumbs} btnincluir={false} admin={false}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3" onSubmit={validaForm}>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_dsc_chassi" placeholder="" name="ve100_dsc_chassi" value={ve100_dsc_chassi} onChange={evento => set100_dsc_chassi(evento.target.value)} />
                                <label for="floatingInput">Chassi</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_num_renavam" placeholder="" name="ve100_num_renavam" value={ve100_num_renavam} onChange={evento => set100_num_renavam(evento.target.value)} />
                                <label for="floatingInput">Renavam</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_dsc_placa" placeholder="" name="ve100_dsc_placa" value={ve100_dsc_placa} onChange={evento => set100_dsc_placa(evento.target.value)} />
                                <label for="floatingInput">Placa</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_key_nfe" placeholder="" value={ve100_key_nfe} onChange={evento => set100_key_nfe(evento.target.value)} />
                                <label for="floatingInput">Chave da NFs</label>
                            </div>
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                                <button type="submit" class="btn btn-primary-blue btn-primary">Salvar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Incluir;

