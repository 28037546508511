import { useEffect, useState } from "react";
import { frotistaAPI } from "../../servicos/frotista";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Paginacao from "../Listagem/paginacao";

function DebitoTabela() {

    const breadcrumbs = ['Home', 'Veículos', 'Débitos'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [PesquisaVeiculo, setPesquisaVeiculo] = useState([]);

    useEffect(() => {
        fetchDebitos()
    }, []);

    async function fetchDebitos() {

        frotistaAPI.get('')
            .then(resposta => {
                const Veiculos = resposta.data.veiculos;
                Veiculos.map(veiculo => {
                    const Debitos = veiculo.debitos;
                    Debitos.map(multa => {
                        setPesquisaVeiculo(multa.multas);
                    })                
                })                
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }
                }
            })

    }


    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Débitos' breadcrumbs={breadcrumbs} btnincluir={false} destino='' admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Orgão/Auto de Infração</th>
                                    <th class="topo" scope="col">Infração/Enquadramento</th>
                                    <th class="topo" scope="col">Data/Hora</th>
                                    <th class="topo" scope="col">Local</th>
                                    <th class="topo" scope="col">Valor Original/Atual</th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaVeiculo.map(multa => (
                                    <tr>
                                        <td>
                                            {multa.nomeOrgaoAutuador}<br />
                                            <span class="email">
                                                {multa.codAuto}
                                            </span>
                                        </td>
                                        <td>
                                            {multa.descrInfracao}<br />
                                            <span class="email">
                                                {multa.enquadramento}
                                            </span>
                                        </td>
                                        <td>
                                            {multa.dataInfracao}<br />
                                            <span class="email">
                                                {multa.horaInfracao}
                                            </span>
                                        </td>
                                        <td>
                                            {multa.localInfracao}<br />
                                        </td>
                                        <td>
                                            {multa.valorOriginal}<br />
                                            <span class="email">
                                                {multa.valorACobrar}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Paginacao esconder={true} />
        </div>
    )

}

export default DebitoTabela;