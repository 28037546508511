import Logo from '../Logo';

function MenuLateralMobile(){
    return (
            <nav class="navbar navbar-expand-lg py-4 py-lg-0 shadow desktop-hide">
                <div class="container">
                    <Logo />
                    <button class="navbar-toggler border-0 ms-auto" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar-user" aria-controls="top-navbar-user">
                        <span class="material-symbols-outlined">account_circle</span>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="top-navbar-user" aria-labelledby="top-navbarLabel">
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar-user" aria-controls="top-navbar-user">
                            <div class="d-flex justify-content-between p-3">
                                <Logo />
                                <span class="material-symbols-outlined">close</span>
                            </div>
                        </button>
                        <ul class="navbar-nav ms-lg-auto p-4 p-lg-0">
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">manage_accounts</span>Editar Perfil
                                </a>
                            </li>
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">logout</span>Logout
                                </a>
                            </li>
                        </ul>
                    </div>
                    <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar" aria-controls="top-navbar">
                        <span class="material-symbols-outlined">menu</span>
                    </button>
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="top-navbar" aria-labelledby="top-navbarLabel">
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#top-navbar" aria-controls="top-navbar">
                            <div class="d-flex justify-content-between p-3">
                                <Logo />
                                <span class="material-symbols-outlined">close</span>
                            </div>
                        </button>
                        <ul class="navbar-nav ms-lg-auto p-4 p-lg-0">
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="#">
                                    <span class="material-symbols-outlined">bar_chart_4_bars</span>Relatórios
                                </a>
                            </li>
                            <li class="nav-item px-3 px-lg-0 py-1 py-lg-4">
                                <a class="nav-link" href="listagem-filiados.html">
                                    <span class="material-symbols-outlined">store</span>Afiliados
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    )
}

export default MenuLateralMobile;
