function FiliadoCard(){

    return(
        <div class="row row-padding-conteudo row-cols-1 row-cols-md-3 g-3 desktop-hide">
            <div class="col-sm-12 col-md-6 col-lg-4">
                <a href="">
                    <div class="card">
                        <img src="images/teste.png" class="card-img-top" alt="..."/>
                        <div class="card-body">
                            <div class="row mb-3"> 
                                <div class="col-8">
                                    <h5 class="card-title">Ledupa Rent a Car</h5>
                                </div>
                                <div class="col-4">
                                    <span class="badge text-bg-success-card">Ativo</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3"> 
                            <p class="card-text center-icons">
                                <span class="material-symbols-outlined">gavel</span>00.000.000/0001-00
                            </p>
                            <p class="card-text center-icons">
                                <span class="material-symbols-outlined">phone</span>(41) 99999-9999<br/>
                            </p>
                            <p class="card-text center-icons">
                                <span class="material-symbols-outlined">mail</span>contato@leduparentacar.com.br
                            </p>
                        </div>
                        <div class="row">
                            <a href="#" class="btn btn-desativar">Desativar</a>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default FiliadoCard;