import { useEffect, useState } from "react";
import { veiculosAPI } from "../../servicos/veiculos";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Paginacao from "../Listagem/paginacao";
import UsuarioCard from "./card";

function VeiculoTabela(){

    const breadcrumbs = ['Home','Veículos'];

    const [exibeErro, setExibeErro] = useState(false);    
    const [mensagemErro, setMensagemErro] = useState('');    

    const [exibeSucesso, setExibeSucesso] = useState(false);    
    const [mensagemSucesso, setMensagemSucesso] = useState('');    

    const [PesquisaVeiculo, setPesquisaVeiculo] = useState([]);

    useEffect(() => {
        fetchVeiculos()
    }, []);

    async function fetchVeiculos(){

        const fi100_key_public = sessionStorage.getItem('key');
        veiculosAPI.get(`/${fi100_key_public}`)
        .then(resposta => {
            setPesquisaVeiculo(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        

    let navigate = useNavigate();
    function Editar(id){
        let path = `/filiado/veiculo/editar/${id}`;
        navigate(path);
    }    

    function Emplacar(id){
        let path = `/filiado/veiculo/emplacar/${id}`;
        navigate(path);
    }    

    function Debitos(){
        let path = `/filiado/veiculo/detran`;
        navigate(path);
    }    

    async function DesativarVeiculo(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        const fi100_key_public = sessionStorage.getItem('key');
        veiculosAPI.patch(`/desativar`,
            {
                'fi100_key' : fi100_key_public,
                've100_key' : key
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Veículo atualizado com sucesso.');    
                fetchVeiculos();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }

    async function AtivarVeiculo(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        const fi100_key_public = sessionStorage.getItem('key');
        veiculosAPI.patch(`/reativar`,
            {
                'fi100_key' : fi100_key_public,
                've100_key' : key
            })
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Veículo atualizado com sucesso.');    
                fetchVeiculos();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })

    }

    return(
        <div class="row">
            <div class="container">
                <Topo titulo='Veículos' breadcrumbs={breadcrumbs} btnincluir={true} destino='/filiado/veiculo/novo' admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />            
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Placa</th>
                                    <th class="topo" scope="col">Renavam</th>
                                    <th class="topo" scope="col">Chassi</th>
                                    <th class="topo" scope="col">Status</th>
                                    <th class="topo" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                { PesquisaVeiculo.map( veiculo => (
                                    <tr>
                                        <td>
                                            {veiculo.ve100_dsc_placa}<br/>
                                        </td>
                                        <td>
                                            {veiculo.ve100_num_renavam}<br/>
                                        </td>
                                        <td>
                                            {veiculo.ve100_dsc_chassi}<br/>
                                        </td>

                                        <td>
                                            {veiculo.ve100_ind_desativado
                                                ? <span class="badge text-bg-danger" onClick={() => AtivarVeiculo(veiculo.ve100_key_public)}>Desativado</span>
                                                : <span class="badge text-bg-success" onClick={() => DesativarVeiculo(veiculo.ve100_key_public)}>Ativado</span>
                                            }                                                                                   
                                        </td>
                                        <td>
                                        <div class="dropdown">
                                            <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="material-symbols-outlined">more_vert</span>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a class="dropdown-item align-icon" onClick={() => Editar(veiculo.ve100_key_public)}>
                                                        <span class="material-symbols-outlined">edit</span>Editar
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item align-icon" onClick={() => Debitos(veiculo.ve100_key_public)}>
                                                        <span class="material-symbols-outlined">request_quote</span>Débitos
                                                    </a>
                                                </li>
                                                {(veiculo.ve100_dsc_placa === "") && (veiculo.ve100_num_renavam === "") && (veiculo.ve100_dsc_chassi !== "") && (veiculo.ve100_key_nfe !== "") && 
                                                    <li>
                                                        <a class="dropdown-item align-icon" onClick={() => Emplacar(veiculo.ve100_key_public)}>
                                                            <span class="material-symbols-outlined">no_crash</span>Emplacar
                                                        </a>
                                                    </li>
                                                }
                                                <li>
                                                    {veiculo.ve100_ind_desativado && 
                                                        <a class="dropdown-item align-icon" onClick={() => AtivarVeiculo(veiculo.ve100_key_public)}>
                                                            <span class="material-symbols-outlined">do_not_disturb_on</span>Ativar
                                                        </a>
                                                    }
                                                    {!veiculo.ve100_ind_desativado && 
                                                        <a class="dropdown-item align-icon action-disable" onClick={() => DesativarVeiculo(veiculo.ve100_key_public)}>
                                                            <span class="material-symbols-outlined">do_not_disturb_on</span>Desativar
                                                        </a>
                                                    }
                                                </li>
                                            </ul>
                                        </div> 
                                        </td>
                                    </tr>
                                ) ) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
            <UsuarioCard />
            <Paginacao esconder={true} />
        </div>        
    )

}

export default VeiculoTabela;