import Cadastro from '../componentes/Filiado/Cadastro';

function FiliadoCadastro(props) {
  
  const mostraEditar = props.editar;
  const mostraSenha = props.senha;
  const mostraIncluir = props.incluir;
  
  return (
    <Cadastro editar={mostraEditar} senha={mostraSenha} incluir={mostraIncluir} />
  );
}

export default FiliadoCadastro;
