import logoSindiloc from '../../imagens/logo-Sindiloc-PR.png';
import styled from 'styled-components';

const LogoContainner = styled.div`
    display: flex;  
    align-items: center;
    margin-top:10px;
`

const LogoImage = styled.img`
  margin: 10px;
  max-width: 250px;
  max-height: 49px;
`

function Logo(){

    return (
        <LogoContainner>
          <LogoImage
            src={logoSindiloc} 
            alt='Logo Sindiloc PR'
          />
        </LogoContainner>
    )

}

export default Logo;