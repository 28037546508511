import { useEffect, useState } from "react";
import { usuariosAPI } from "../../servicos/usuarios";
import { useNavigate } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Paginacao from "../Listagem/paginacao";
import UsuarioCard from "./card";

function UsuarioTabela(){

    const breadcrumbs = ['Admin','Usuarios'];

    const [exibeErro, setExibeErro] = useState(false);    
    const [mensagemErro, setMensagemErro] = useState('');    

    const [exibeSucesso, setExibeSucesso] = useState(false);    
    const [mensagemSucesso, setMensagemSucesso] = useState('');    

    const [PesquisaUsuario, setPesquisaUsuario] = useState([]);

    useEffect(() => {
        fetchUsuarios()
    }, []);

    async function fetchUsuarios(){

        usuariosAPI.get('/')
        .then(resposta => {
            setPesquisaUsuario(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        

    let navigate = useNavigate();
    function Editar(id){
        let path = `/admin/usuario/editar/${id}`;
        navigate(path);
    }    

    function ResetarSenha(key) {
        let path = `/admin/usuario/senha/${key}`;
        navigate(path);
    }

    async function DesativarUsuario(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        usuariosAPI.patch(`/desativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Usuário atualizado com sucesso.');    
                fetchUsuarios();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }

    async function AtivarUsuario(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        usuariosAPI.patch(`/reativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Usuário atualizado com sucesso.');    
                fetchUsuarios();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })

    }

    return(
        <div class="row">
            <div class="container">
                <Topo titulo='Usuários' breadcrumbs={breadcrumbs} btnincluir={true} destino='/admin/usuario/novo' admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />            
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Usuário</th>
                                    <th class="topo" scope="col">Status</th>
                                    <th class="topo" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                { PesquisaUsuario.map( usuario => (
                                    <tr>
                                        <td>
                                            {usuario.us100_dsc_usuario}<br/>
                                            <span class="email">
                                                {usuario.us100_dsc_email}
                                            </span>
                                        </td>
                                        <td>
                                            {usuario.us100_ind_desativado
                                                ? <span class="badge text-bg-danger" onClick={() => AtivarUsuario(usuario.us100_key_public)}>Desativado</span>
                                                : <span class="badge text-bg-success" onClick={() => DesativarUsuario(usuario.us100_key_public)}>Ativado</span>
                                            }                                                                                   
                                        </td>
                                        <td>
                                        <div class="dropdown">
                                            <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="material-symbols-outlined">more_vert</span>
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a class="dropdown-item align-icon" onClick={() => Editar(usuario.us100_key_public)}>
                                                        <span class="material-symbols-outlined">edit</span>Editar
                                                    </a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item align-icon" onClick={() => ResetarSenha(usuario.us100_key_public)}>
                                                        <span class="material-symbols-outlined">password</span>Alterar Senha
                                                    </a>
                                                </li>
                                                <li>
                                                    {usuario.us100_ind_desativado && 
                                                        <a class="dropdown-item align-icon" onClick={() => AtivarUsuario(usuario.us100_key_public)}>
                                                            <span class="material-symbols-outlined">do_not_disturb_on</span>Ativar
                                                        </a>
                                                    }
                                                    {!usuario.us100_ind_desativado && 
                                                        <a class="dropdown-item align-icon action-disable" onClick={() => DesativarUsuario(usuario.us100_key_public)}>
                                                            <span class="material-symbols-outlined">do_not_disturb_on</span>Desativar
                                                        </a>
                                                    }
                                                </li>
                                            </ul>
                                        </div> 
                                        </td>
                                    </tr>
                                ) ) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
            <UsuarioCard />
            <Paginacao esconder={true} />
        </div>        
    )

}

export default UsuarioTabela;