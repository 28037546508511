import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Topo from "../Topo/topo";
import ListagemFeedback from "../Listagem/feedback";
import { veiculosAPI } from "../../servicos/veiculos";
import { frotistaAPI } from "../../servicos/frotista";
import { QrCodePix } from "qrcode-pix";


function Emplacar() {

    const breadcrumbs = ['Home', 'Veículo', 'Emplacar'];

    const [exibeErro, setExibeErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');

    const [exibeSucesso, setExibeSucesso] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState('');

    const [ve100_dsc_placa, set100_dsc_placa] = useState('');
    const [ve100_num_renavam, set100_num_renavam] = useState('');
    const [ve100_dsc_chassi, set100_dsc_chassi] = useState('');
    const [ve100_key_nfe, set100_key_nfe] = useState('');
    const [ve100_key_public, set100_key_public] = useState('');

    const [ve101_num_processo,set101_num_processo] = useState('');
    const [ve101_dat_ssv,set101_dat_ssv] = useState('');
    const [ve101_dat_vencimento,set101_dat_vencimento] = useState('');
    const [ve101_num_guia,set101_num_guia] = useState('');
    const [ve101_dsc_guia,set101_dsc_guia] = useState('');
    const [ve101_vlr_total,set101_vlr_total] = useState('');
    const [ve101_cod_barra,set101_cod_barra] = useState('');
    const [ve101_dsc_barra,set101_dsc_barra] = useState('');
    const [ve101_cod_pix,set101_cod_pix] = useState('');
    const [ve101_obs_ssv,set101_obs_ssv] = useState('');

    const [qrCode, setQrCode] = useState('');
    const [rawPix, setRawPix] = useState('');    

    let navigate = useNavigate();
    const Voltar = () => {
        let path = '/filiado/veiculo';
        navigate(path);
    }

    const parametros = useParams();

    useEffect(() => {
        if (parametros.hash) {
            buscarVeiculo(parametros.hash);
        }
    }, [parametros])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function RealizarLogout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('Nome');
        sessionStorage.removeItem('Key');
        await sleep(3000);
        navigate('/');
    }

    async function buscarVeiculo(hash) {

        veiculosAPI.get(`/buscar/${hash}`)
            .then(resposta => {
                set100_dsc_chassi(resposta.data.ve100_dsc_chassi);
                set100_key_nfe(resposta.data.ve100_key_nfe);
                set100_dsc_placa(resposta.data.ve100_dsc_placa);
                set100_num_renavam(resposta.data.ve100_num_renavam);
                set100_key_public(resposta.data.ve100_key_public);                
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                        RealizarLogout();
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )
    }

    async function SolicitarEmplacamento(){

        setExibeSucesso(false);
        setMensagemSucesso('');
        setExibeErro(false);
        setMensagemErro('');
/*
        setExibeSucesso(true);
        setMensagemSucesso('Processo cadastrado com sucesso.');

        set101_num_processo('92.618187-4');
        set101_dat_ssv('12/09/2024');
        set101_dat_vencimento('22/09/2024');
        set101_num_guia('001.2.10112974-6');
        set101_dsc_guia('Primeiro Registro de Veículo');
        set101_vlr_total('R$ 304,74');
        set101_cod_barra('85890000014-0 17700328242-7 64072024247-6 87075299036-7');
        set101_dsc_barra('Pagar po PIX ou nos seguintes bancos: Banco do Brasil, Rendimento, Santander, Sicoob ou Sicredi');
        set101_cod_pix('00020101021226850014br.gov.bcb.pix2563qrcodepix.bb.com.br/pix/v2/8020cb7c-9569-4e8b-87cd-4e5d1fd2adcb5204000053039865406159.225802BR5925DEPARTAMENTO DE TRANSITO 6008CURITIBA62070503***6304A99D');
        set101_obs_ssv('Não receber após vencimento');        

        const qrCodePix = QrCodePix({
            version: '01',
            key: 'c51463c5-f6be-45c7-98e8-02dd0c33d742',
            name: 'Ledupa Serviços de Informática Ltda',
            city: 'Curitiba',
            transactionId: '123456',
            message: 'Recebimento',
            value: 1.00,
        })

        const rawPixStr = qrCodePix.payload()
        const qrCodeBase64 = await qrCodePix.base64()

        setRawPix(rawPixStr)
        setQrCode(qrCodeBase64)
*/

        frotistaAPI.post(`/veiculo/emplacar/${ve100_key_public}`)
            .then(resposta => {

                setExibeSucesso(true);
                setMensagemSucesso('Processo cadastrado com sucesso.');

                set101_num_processo(resposta.data.ve101_num_processo);
                set101_dat_ssv(resposta.data.ve101_dat_ssv);
                set101_dat_vencimento(resposta.data.ve101_dat_vencimento);
                set101_num_guia(resposta.data.ve101_num_guia);
                set101_dsc_guia(resposta.data.ve101_dsc_guia);
                set101_vlr_total(resposta.data.ve101_vlr_total);
                set101_cod_barra(resposta.data.ve101_cod_barra);
                set101_dsc_barra(resposta.data.ve101_dsc_barra);
                set101_cod_pix(resposta.data.ve101_cod_pix);
                set101_obs_ssv(resposta.data.ve101_obs_ssv);        
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {
                    setExibeErro(true);
                    setMensagemErro('Processo Criado com sucesso.');
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                        RealizarLogout();
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            }
        )        

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Emplacar Veículo' breadcrumbs={breadcrumbs} btnincluir={false} admin={false} />
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
                <div class="row row-padding-conteudo">
                    <div class="col-12">
                        <form class="row g-3">
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_dsc_chassi" placeholder="" name="ve100_dsc_chassi" value={ve100_dsc_chassi} disabled readOnly />
                                <label for="floatingInput">Chassi</label>
                            </div>
                            <div class="col-md-6 form-floating">
                                <input type="inputtext" class="form-control" id="ve100_key_nfe" placeholder="" value={ve100_key_nfe} disabled readOnly />
                                <label for="floatingInput">Chave da NFs</label>
                            </div>
                            <div class="col-md-3 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_num_processo" placeholder="" value={ve101_num_processo} disabled readOnly />
                                <label for="floatingInput">Número do Processo</label>
                            </div>
                            <div class="col-md-3 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_num_guia" placeholder="" value={ve101_num_guia} disabled readOnly />
                                <label for="floatingInput">Número da Guia</label>
                            </div>
                            <div class="col-md-3 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_vlr_total" placeholder="" value={ve101_vlr_total} disabled readOnly />
                                <label for="floatingInput">Valor Total</label>
                            </div>
                            <div class="col-md-3 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_dat_vencimento" placeholder="" value={ve101_dat_vencimento} disabled readOnly />
                                <label for="floatingInput">Data de Vencimento</label>
                            </div>
                            <div class="col-md-12 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_dsc_guia" placeholder="" value={ve101_dsc_guia} disabled readOnly />
                                <label for="floatingInput">Observação da Guia</label>
                            </div>
                            <div class="col-md-12 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_cod_barra" placeholder="" value={ve101_cod_barra} disabled readOnly />
                                <label for="floatingInput">Código de Barra</label>
                            </div>
                            <div class="col-md-12 form-floating">
                                <img src={qrCode} alt={'QR Code PIX'}/>                                
                            </div>
                            <div class="col-md-12 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_cod_pix" placeholder="" value={ve101_cod_pix} disabled readOnly />
                                <label for="floatingInput">Código do Pix</label>
                            </div>
                            <div class="col-md-12 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_dsc_barra" placeholder="" value={ve101_dsc_barra} disabled readOnly />
                                <label for="floatingInput">Observação Código de Barras</label>
                            </div>
                            <div class="col-md-2 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_dat_ssv" placeholder="" value={ve101_dat_ssv} disabled readOnly />
                                <label for="floatingInput">Data SSV</label>
                            </div>
                            <div class="col-md-10 form-floating">
                                <input type="inputtext" class="form-control" id="ve101_obs_ssv" placeholder="" value={ve101_obs_ssv} disabled readOnly />
                                <label for="floatingInput">Observação SSV</label>
                            </div>                            
                            {(ve100_dsc_placa === "") && (ve100_num_renavam === "") && (ve100_dsc_chassi !== "") && (ve100_key_nfe !== "") && 
                                <a class="dropdown-item align-icon" onClick={SolicitarEmplacamento} ><span class="material-symbols-outlined">no_crash</span>Solicitar Emplacamento</a>
                            }
                            <div class="col-12 text-end cadastro-btn">
                                <button type="button" class="btn btn-desativar btn-primary" onClick={Voltar}>Voltar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Emplacar;

