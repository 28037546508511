import { useEffect, useState } from "react";
import { filiadoAPI } from "../../servicos/filiado";
import { useNavigate } from "react-router-dom";
import ListagemFeedback from "../Listagem/feedback";
import ListagemOpcoes from "../Listagem/opcoes";
import Topo from "../Topo/topo";
import FiliadoCard from "./card";
import Paginacao from "../Listagem/paginacao";

function FiliadoTabela() {

    const breadcrumbs = ['Admin','Filiados'];
    const [PesquisaFiliados, setPesquisaFiliados] = useState([]);

    const [exibeErro, setExibeErro] = useState(false);    
    const [mensagemErro, setMensagemErro] = useState('');    

    const [exibeSucesso, setExibeSucesso] = useState(false);    
    const [mensagemSucesso, setMensagemSucesso] = useState('');    

    useEffect(() => {
        fetchFiliados();
    }, []);

    async function fetchFiliados(){

        filiadoAPI.get('/')
        .then(resposta => {
            setPesquisaFiliados(resposta.data);
        })
        .catch(erro => {          
            if(erro?.resposta?.data?.message){
                setExibeErro(true);
                setMensagemErro(erro.resposta.data.message);
            }else{
                if (erro.response?.status === 401) {
                    setExibeErro(true);
                    setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');
                  }else{
                    setExibeErro(true);
                    setMensagemErro('Ops! Ocorreu um erro inesperado.');
                  }                                           
            }        
        })               
        
    }        

    let navigate = useNavigate();
    function Editar(key) {
        let path = `/admin/filiado/editar/${key}`;
        navigate(path);
    }

    function ResetarSenha(key) {
        let path = `/admin/filiado/senha/${key}`;
        navigate(path);
    }
    

    async function DesativarFiliado(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        filiadoAPI.patch(`/desativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Filiado atualizado com sucesso.');    
                fetchFiliados();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })
    }

    async function AtivarFiliado(key) {

        setExibeSucesso(false);
        setMensagemSucesso('');    
        setExibeErro(false);
        setMensagemErro('');

        filiadoAPI.patch(`/reativar/${key}`)
            .then(resposta => {
                setExibeSucesso(true);
                setMensagemSucesso('Filiado atualizado com sucesso.');    
                fetchFiliados();
            })
            .catch(erro => {
                if (erro?.resposta?.data?.message) {                    
                    setExibeErro(true);
                    setMensagemErro(erro.resposta.data.message);
                } else {
                    if (erro.response?.status === 401) {
                        setExibeErro(true);
                        setMensagemErro('Ops! Sessão Expirada! Faça um novo login.');    
                    } else {
                        setExibeErro(true);
                        setMensagemErro('Ops! Ocorreu um erro inesperado.');
                    }

                }
            })

    }

    return (
        <div class="row">
            <div class="container">
                <Topo titulo='Filiado' breadcrumbs={breadcrumbs} btnincluir={true} destino='/admin/filiado/novo' admin={true}/>
                <ListagemFeedback
                    displayErro={exibeErro}
                    mensagemErro={mensagemErro}
                    displaySucesso={exibeSucesso}
                    mensagemSucesso={mensagemSucesso}
                />
            </div>
            <ListagemOpcoes
                esconderTamanhoPagina={true}
                esconderOpcaoFiltro={true}
                esconderCampoFiltro={true}
            />            
            <div class="row row-padding-conteudo" >
                <div class="col-12 mobile-hide tablet-hide">
                    <div class="table-responvive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="topo" scope="col">Filiado</th>
                                    <th class="topo" scope="col">CNPJ</th>
                                    <th class="topo" scope="col">Telefone</th>
                                    <th class="topo" scope="col">Status</th>
                                    <th class="topo" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {PesquisaFiliados.map(filiado => (
                                    <tr>
                                        <td>
                                            {filiado.fi100_dsc_filiado}<br />
                                            <span class="email">
                                                {filiado.fi100_dsc_email}
                                            </span>
                                        </td>
                                        <td>{filiado.fi100_num_cnpj}</td>
                                        <td>{filiado.fi100_num_telefone}</td>
                                        <td>
                                            {filiado.fi100_ind_desativado
                                                ? <span class="badge text-bg-danger" onClick={() => AtivarFiliado(filiado.fi100_key_public)}>Desativado</span>
                                                : <span class="badge text-bg-success" onClick={() => DesativarFiliado(filiado.fi100_key_public)}>Ativado</span>
                                            }
                                        </td>
                                        <td>
                                            <div class="dropdown">
                                                <button class="dropdown-acao btn-dropdown btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span class="material-symbols-outlined">more_vert</span>
                                                </button>
                                                <ul class="dropdown-menu">
                                                    <li>
                                                        <a class="dropdown-item align-icon" onClick={() => Editar(filiado.fi100_key_public)}>
                                                            <span class="material-symbols-outlined">edit</span>Editar
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a class="dropdown-item align-icon" onClick={() => ResetarSenha(filiado.fi100_key_public)}>
                                                            <span class="material-symbols-outlined">password</span>Alterar Senha
                                                        </a>
                                                    </li>
                                                    <li>
                                                        {filiado.fi100_ind_desativado && 
                                                            <a class="dropdown-item align-icon" onClick={() => AtivarFiliado(filiado.fi100_key_public)}>
                                                                <span class="material-symbols-outlined">do_not_disturb_on</span>Ativar
                                                            </a>
                                                        }
                                                        {!filiado.fi100_ind_desativado && 
                                                            <a class="dropdown-item align-icon action-disable" onClick={() => DesativarFiliado(filiado.fi100_key_public)}>
                                                                <span class="material-symbols-outlined">do_not_disturb_on</span>Desativar
                                                            </a>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <FiliadoCard />
            <Paginacao esconder={true} />
        </div>
    )

}

export default FiliadoTabela;