import MenuLateral from "../MenuLateral/menulateral";
import MenuLateralMobile from "../MenuLateral/menulateralmobile";
import DebitoTabela from "./tabela";

function Listagem() {  
  
    return (
        <div class="container-fluid">
            <div class="row">
                <MenuLateral menu="veiculo" admin={false}/>
                <MenuLateralMobile />
                <div class="col-12 col-lg-2 mobile-hide invisible"></div>            
                <div id="conteudo" class="col-12 col-lg-10">
                    <DebitoTabela />
                </div>           
            </div>
        </div>  
    );
  
  }
  
  export default Listagem;