import Listagem from '../componentes/Veiculo/Listagem';
import Cadastro from '../componentes/Veiculo/Cadastro';

function Veiculo(props) {  
  
  return (

    <section>

      { props.acao === "Listar" &&
        <Listagem />
      }
      { props.acao !== "Listar" &&
        <Cadastro acao={props.acao} />
      }    

    </section>

  );

}

export default Veiculo;
