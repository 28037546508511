import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import './bootstrap/style.css';
import './bootstrap/stylesidebar.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Admin from './rotas/Admin';
import Usuario from './rotas/Usuario';
import Filiado from './rotas/Filiado';
import FiliadoCadastro from './rotas/FiliadoCadastro';
import Home from './rotas/Home';
import Veiculo from './rotas/Veiculo';
import Debitos from './rotas/Debitos';

//import { createGlobalStyle } from 'styled-components';
/*
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  li {
    list-style: none;
  }
`
<GlobalStyle />
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>    
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/filiado" element={<Filiado />} />
        <Route path="/admin/filiado/novo" element={<FiliadoCadastro editar={false} senha={false} incluir={true} />} />
        <Route path="/admin/filiado/editar/:hash" element={<FiliadoCadastro editar={true} senha={false} incluir={false} />} />
        <Route path="/admin/filiado/senha/:hash" element={<FiliadoCadastro editar={false} senha={true} incluir={false}/>} />
        <Route path="/admin/usuario" element={<Usuario acao="Listar" />} />
        <Route path="/admin/usuario/novo" element={<Usuario acao="Novo" />} />
        <Route path="/admin/usuario/editar/:hash" element={<Usuario acao="Editar" />} />
        <Route path="/admin/usuario/senha/:hash" element={<Usuario  acao="Senha" />} />
        <Route path="/filiado/veiculo" element={<Veiculo acao="Listar" />} />
        <Route path="/filiado/veiculo/novo" element={<Veiculo acao="Novo" />} />
        <Route path="/filiado/veiculo/editar/:hash" element={<Veiculo acao="Editar" />} />
        <Route path="/filiado/veiculo/emplacar/:hash" element={<Veiculo acao="Emplacar" />} />
        <Route path="/filiado/veiculo/detran" element={<Debitos />} />

        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>        
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
