import Listagem from '../componentes/Debitos/Listagem';

function Debitos(props) {  
  
  return (

    <section>
        <Listagem />
    </section>

  );

}

export default Debitos;
